import React, { useCallback, useEffect } from 'react';

import InvitationCard from 'components/templates/InvitationCard';
import echo from 'services/socket';
import { useAppSelector } from 'store';
import { useAppDispatch } from 'store/hooks';
import { getMeAction } from 'store/me';

const Invitation: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: profileData } = useAppSelector((state) => state.me);
  const socketLivestream = useCallback(() => {
    dispatch(getMeAction()).unwrap();
  }, [dispatch]);
  useEffect(() => {
    if (profileData) {
      const channel = echo.private(`EventTime.${profileData.event_time_code}`);
      channel.listen('updated', socketLivestream);
      return () => {
        channel.stopListening('updated', socketLivestream);
      };
    }
    return () => {};
  }, [profileData, socketLivestream]);
  return (
    <div className="t-invitation">
      <InvitationCard
        profile={profileData}
      />
    </div>
  );
};

export default Invitation;
