import React from 'react';

import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';

interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => (
  <div className="o-footer">
    <div className="o-footer_phone">
      <Text modifiers={['md-sm', '700', 'pigment', 'uppercase', 'center']} type="span">
        Tổng đài liên hệ:
        <Link href="tel:0918454049" useExternal> 0918 45 40 49 </Link>
      </Text>
    </div>
  </div>
);

Footer.defaultProps = {
};

export default Footer;
