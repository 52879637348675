import {
  GetMemberParams, GetMemberResponse, LoginMemberParams, MemberDataTypes,
} from './types';

import axiosInstance from 'services/common/instance';
import { AuthTypes } from 'store/type';

export const loginMemberService = async (
  params: LoginMemberParams,
): Promise<AuthTypes> => {
  const res = await axiosInstance.post('members/login', params);
  return res.data.data;
};

export const getProfileService = async (): Promise<MemberDataTypes> => {
  const res = await axiosInstance.get('members/me');
  return res.data;
};

export const getMemberService = async (params: GetMemberParams): Promise<GetMemberResponse> => {
  const res = await axiosInstance.get(`members/${params}`);
  return res.data.data;
};

export const forgotPasswordService = async (
  params: GetMemberParams,
): Promise<GetMemberResponse> => {
  const res = await axiosInstance.post(`members/${params}/forgot-password`);
  return res.data.data;
};
