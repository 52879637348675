import Echo from 'laravel-echo';
import Pusher, { Authorizer, AuthorizerCallback, Channel } from 'pusher-js';

import axiosInstance from 'services/common/instance';

const isDev = process.env.NODE_ENV === 'development';
const { proxy } = require('../../../package.json');

const proxyHost = new URL(proxy).host;

const echo = new Echo({
  broadcaster: 'pusher',
  namespace: null,
  client: new Pusher(process.env.REACT_APP_ECHO_KEY || '', {
    wsHost: isDev ? proxyHost : window.location.host,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authorizer(channel: Channel): Authorizer {
      return {
        async authorize(
          socketId: string,
          callback: AuthorizerCallback,
        ): Promise<void> {
          try {
            const res = await axiosInstance.post(
              'broadcasting/auth',
              JSON.stringify({
                socket_id: socketId,
                channel_name: channel.name,
              }),
            );

            callback(null, res.data);
          } catch (err) {
            callback(err as Error, { auth: '' });
          }
        },
      };
    },
  }),
});

export default echo;
