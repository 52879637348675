import React, { useEffect, useState } from 'react';

import { dataHeaderSecondaryTable } from 'assets/dataDummy/table';
// import bgPrizeTable from 'assets/images/prizes/bg-prizeTable.jpg';
import prizeLabelImg from 'assets/images/prizes/prize-label.png';
import clplogo from 'assets/images/prizes/prize-logo.png';
import prizeValueImg from 'assets/images/prizes/prize-value.png';
import logo from 'assets/images/quiz/quiz-logo.png';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import Table, { TableCell, TableHeader, TableRow } from 'components/organisms/Table';
import { PagingTypes } from 'containers/Prize';
import mapModifiers from 'utils/functions';

export type PrizeTableTypes = {
  id: number;
  name: string;
  address: string;
  number: number;
  area?: string;
}

export type PrizeInfoTypes = {
  number: number;
  title: string;
  value: string;
}

interface PrizeTableProps {
  prizeData?: PrizeTableTypes[];
  prizeInfo?: PrizeInfoTypes;
  splitTable?: number;
  recordNumber: number;
  pagingData?: PagingTypes;
  type?: number;
}

const PrizeTable: React.FC<PrizeTableProps> = ({
  prizeData, pagingData,
  prizeInfo, splitTable = 5, recordNumber, type,
}) => {
  const [split, setSplit] = useState(0);
  const tableLeft = [...Array(recordNumber)].slice(split, split * 2);
  const tableLeftPage = [...Array(recordNumber)].slice(
    (pagingData?.startIndex || 0) + (split - 1) + 1, (pagingData?.endIndex || 0) + 1,
  );
  const dataLeft = pagingData ? tableLeftPage : tableLeft;
  const dataStart = [...Array(recordNumber)]?.slice(0, split);

  useEffect(() => {
    if (splitTable) setSplit(splitTable);
  }, [splitTable]);

  useEffect(() => {
    if (dataStart.length !== dataLeft.length && recordNumber >= 30) {
      setSplit((dataStart.length + dataLeft.length) / 2);
    }
  }, [dataStart, dataLeft, recordNumber]);

  return (
    <div
      className={mapModifiers('t-prizeTable',
        prizeData && prizeData?.length < 1 && 'empty',
        (recordNumber > split) && 'double', !!type && `${type}`)}
    >
      <div className="t-prizeTable_header">
        <div className="t-prizeTable_logo">
          <Image imgSrc={logo} alt="Quiz Logo" ratio="234x23" size="contain" />
        </div>
        <div className="t-prizeTable_clplogo">
          <Image imgSrc={clplogo} alt="Quiz Logo" ratio="277x100" size="contain" />
        </div>
      </div>
      <div className="t-prizeTable_wrap">
        <Container fullScreen>
          {
            prizeInfo
            && (
              <div className="t-prizeTable_top">
                <div className="t-prizeTable_top_item">
                  <div className="t-prizeTable_label">
                    <img src={prizeLabelImg} alt="label" />
                    <Text modifiers={['30x40', '700', 'prizeGradient', 'uppercase']}>
                      <Text type="span" modifiers={['30x40', '700', 'darkGoldenrod']}>
                        {prizeInfo.number}
                      </Text>
                      {' '}
                      {prizeInfo.title}
                    </Text>
                  </div>
                </div>
                <div className="t-prizeTable_top_item">
                  <div className="t-prizeTable_value">
                    <img src={prizeValueImg} alt="value" />
                    <Text type="span" modifiers={['30x40', '700', 'white']}>
                      {prizeInfo.value}
                      <Text type="span" modifiers={['24x33', '700', 'white']}>
                        <sup>VNĐ</sup>
                      </Text>
                    </Text>
                    <Text type="span" modifiers={['30x40', '700', 'white']}>
                      /GIẢI
                    </Text>
                  </div>
                </div>
              </div>
            )
          }
          <div className="t-prizeTable_list">
            <div className="t-prizeTable_list_item">
              <Table
                modifiers="secondary"
                header={(
                  <TableHeader>
                    <TableRow isHead>
                      {dataHeaderSecondaryTable.map((e, i) => (
                        <TableCell isHead key={`tableCell-${i.toString()}`}>
                          <Text modifiers={['md-lg', 'uppercase', '700', 'white', 'center']}>
                            {e.value}
                          </Text>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHeader>
                )}
              >
                {dataStart.map((_, index) => (
                  <TableRow key={`tableRow-${index.toString()}`}>
                    <TableCell>
                      <Text modifiers={['md-lg', 'uppercase', '400', 'center', 'dimGray']}>
                        {pagingData ? pagingData.startIndex + (index + 1) : index + 1}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text modifiers={['md-lg', '700', 'pigment', 'uppercase', 'center']}>
                        {prizeData && prizeData.length > 0 && prizeData[index] ? prizeData[index].number : '-'}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text modifiers={['md-lg', '700', 'pigment', 'uppercase', 'center']}>
                        {prizeData && prizeData.length > 0 && prizeData[index] ? prizeData[index].name : '-'}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text modifiers={['md-lg', 'uppercase', '400', 'center', 'egyptianBlue']}>
                        {prizeData && prizeData.length > 0 && prizeData[index] ? prizeData[index].address : '-'}
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </div>
            {
              prizeData && recordNumber && recordNumber > splitTable
              && (
                <div className="t-prizeTable_list_item">
                  <Table
                    modifiers="secondary"
                    header={(
                      <TableHeader>
                        <TableRow isHead>
                          {dataHeaderSecondaryTable.map((e, i) => (
                            <TableCell isHead key={`tableCell-${i.toString()}`}>
                              <Text modifiers={['md-lg', 'uppercase', '700', 'white', 'center']}>
                                {e.value}
                              </Text>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHeader>
                    )}
                  >
                    {dataLeft.map(
                      (_, index) => (
                        <TableRow key={`tableRow-${index.toString()}`}>
                          <TableCell>
                            <Text modifiers={['md-lg', 'uppercase', '400', 'center', 'dimGray']}>
                              {pagingData ? pagingData.startIndex + index + split + 1
                                : index + split + 1}

                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text modifiers={['md-lg', '700', 'pigment', 'uppercase', 'center']}>
                              {prizeData && prizeData.length > 0 && prizeData[index + split] ? prizeData[index + split].number : '-'}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text modifiers={['md-lg', '700', 'pigment', 'uppercase', 'center']}>
                              {prizeData && prizeData.length > 0 && prizeData[index + split] ? prizeData[index + split].name : '-'}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text modifiers={['md-lg', 'uppercase', '400', 'center']}>
                              {prizeData && prizeData.length > 0 && prizeData[index + split] ? prizeData[index + split].address : '-'}
                            </Text>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </Table>
                </div>
              )
            }
          </div>
        </Container>
      </div>
    </div>
  );
};

PrizeTable.defaultProps = {
  prizeData: undefined,
  prizeInfo: undefined,
  splitTable: undefined,
  pagingData: undefined,
};

export default PrizeTable;
