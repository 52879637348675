import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getGeneralService from 'services/general';
import { GeneralTypes } from 'services/general/type';

interface GeneralState {
  data?: GeneralTypes;
  loading?: boolean;
}

const initialState: GeneralState = {
  data: undefined,
  loading: false,
};

export const getGeneralAction = createAsyncThunk<
GeneralTypes, void, {rejectValue: ErrorResponse}
>(
  'general/getGeneralAction', async (_, { rejectWithValue }) => {
    try {
      const res = await getGeneralService();
      return res;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getGeneralAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getGeneralAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
    builder.addCase(getGeneralAction.rejected, ($state) => {
      $state.loading = false;
    });
  },
});

export default generalSlice.reducer;
