/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Echo from 'laravel-echo';
import { throttle } from 'lodash-es';
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import floating from './floating';

import { BallVariantTypes } from 'components/molecules/Ball';
import { EmojiIconTypes } from 'components/organisms/Emoji';
import Livestream, { PrizeListTypes } from 'components/templates/Livestream';
import { BallType } from 'components/templates/PickingBall';
import { useDidMount } from 'helpers/react-hook';
import useWindowDimensions from 'hooks/useWindowDimension';
import { GetMemberResponse } from 'services/member/types';
import { getPrizesService } from 'services/picking';
import { LuckyDrawsSocketTypes } from 'services/picking/type';
import echo from 'services/socket';
import { getConfigsLiveStreamAction } from 'store/configs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeAction } from 'store/me';
import SLUG_PARAMS from 'utils/constants';
import { numberWithCommas } from 'utils/functions';

const LivestreamContainer: React.FC<{ memberData: GetMemberResponse }> = ({
  memberData,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { livestream } = useAppSelector((state) => state.configs);
  const { data: meData } = useAppSelector((state) => state.me);
  const interactRef = useRef<NodeJS.Timeout>();
  const [fullScreen, setFullscreen] = useState(false);
  const [dataPrize, setDataPrize] = useState<PrizeListTypes[]>([]);
  const [dataBall, setDataBall] = useState<BallType[]>(
    memberData.lucky_draws.map((val) => ({
      value: (val.number_choosed).toString(),
      variant: 'active' as BallVariantTypes,
    })),
  );
  const { width: winWidth } = useWindowDimensions();
  const channelRef = useRef<ReturnType<Echo['private']>>();

  useEffect(() => {
    dispatch(getConfigsLiveStreamAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * GET PRIZE DATA FROM API
   */
  useEffect(() => {
    (async () => {
      const res = await getPrizesService();
      const numberArr = res.map(
        (val) => (val.details.length > 0
          ? val.details.map((item) => item.number_choosed) : undefined),
      ).filter((f) => f !== undefined);
      const bingoNumber = Array.prototype.concat(...numberArr);

      // Set ball bingo if it has exist in API
      setDataBall((prevState) => prevState.map(
        (val) => (bingoNumber.includes(parseInt(val.value, 10))
          ? {
            ...val,
            variant: 'bingo',
          }
          : val),
      ));
      // PRIZE LIST DATA MODIFIER
      setDataPrize(res.map((val) => {
        const awardPrize = val.award ? numberWithCommas(parseInt(val.award, 10)) : 0;
        return {
          id: val.id,
          title: `${val.number_prize} ${val.name}: ${awardPrize} VNĐ/GIẢI`,
          isSpecial: val.number_prize === 1,
          data: val.details.map((item) => ({
            id: item.member_id,
            name: item.member.shop_name,
            address: item.member.address || '-',
            number: item.number_choosed,
          })),
        };
      }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * HANDLE INTERACTION
   */
  // const removeFirstItem = () => {
  //   if (interactRef.current) return;
  //   interactRef.current = setInterval(() => {
  //     setInteraction((prev) => prev.filter((x, i) => i !== 0));
  //   }, 1000);
  // };
  // useEffect(() => {
  //   if (interaction.length < 1 && interactRef.current) {
  //     clearInterval(interactRef.current);
  //     interactRef.current = undefined;
  //   }
  // }, [interaction]);

  const handleCommonInteraction = useCallback((data: EmojiIconTypes) => {
    const resHeight = winWidth && winWidth <= 991 ? '62vh' : '74vh';
    const resWidth = winWidth && winWidth <= 991 ? '63vw' : '68vw';

    floating({
      content: `<div class="emoji-img ${data.name}" />`,
      number: 1,
      duration: 5,
      rep: '1',
      size: 2,
      containerWidth: fullScreen ? '100%' : resWidth,
      containerHeight: fullScreen ? '100vh' : resHeight,
    });
  }, [fullScreen, winWidth]);

  const handleInteraction = useCallback((data: EmojiIconTypes) => {
    const { icon, ...dataWithoutIcon } = data;
    (channelRef.current as any)?.whisper('emoji', dataWithoutIcon);

    handleCommonInteraction(data);
  }, [handleCommonInteraction]);

  const throttledInteraction = useMemo(() => throttle(handleInteraction, 200, {
    trailing: false,
    leading: true,
  }), [handleInteraction]);

  /**
     * SOCKET LISTENER
     */
  const socketGetLuckyNumber = (data: LuckyDrawsSocketTypes) => {
    setTimeout(() => {
      const luckyData = data.luckyDraw;
      setDataBall((prevState) => prevState.map(
        (val) => (luckyData.number_choosed === parseInt(val.value, 10)
          ? {
            ...val,
            variant: 'bingo',
          }
          : val),
      ));

      setDataPrize((prevState) => prevState.map((val) => {
        if (val.id === luckyData.prize_id) {
          return {
            ...val,
            data: [...val.data, {
              id: luckyData.member_id,
              name: luckyData.member.shop_name,
              address: luckyData.member.address || '-', // replace with address field later
              number: luckyData.number_choosed,
            }],
          };
        }
        return val;
      }));
    }, 10000);
  };

  useDidMount(() => {
    const channel = echo.private(`EventTime.${memberData.event_time_code}`);

    channelRef.current = channel;

    const handleEmojiWhisper = (data: EmojiIconTypes) => {
      handleCommonInteraction(data);
    };

    channel.listen('LuckyNumberResult', socketGetLuckyNumber);
    channel.listenForWhisper('emoji', handleEmojiWhisper);

    return () => {
      channel.stopListening('LuckyNumberResult', socketGetLuckyNumber);
      channel.stopListeningForWhisper('emoji', handleEmojiWhisper);
    };
  });
  const dataBallFilter = useMemo(() => {
    const bingo = dataBall.filter((f) => f.variant === 'bingo');
    const normal = dataBall.filter((f) => f.variant === 'active');
    const result = bingo.concat(normal);
    return result;
  }, [dataBall]);

  if (!meData) return <>loading</>;

  return (
    <Livestream
      meData={meData}
      storeName={memberData.name}
      handleFullscreen={(isFUll) => setFullscreen(isFUll)}
      handleInteract={(data) => {
        throttledInteraction(data);
      }}
      handleBack={() => history.push(SLUG_PARAMS.FOYER)}
      prizeListData={dataPrize}
      // prizeListData={[...dataPrize].reverse()}
      numberList={dataBallFilter}
      livestreamConfig={{
        link_livestream: livestream?.link_livestream || '',
        theoplayer_license: process.env.REACT_APP_THEOPLAYER_LICENSE || '',
        theoplayer_library_location: process.env.REACT_APP_THEOPLAYER_LIBRARY_LOCATION || '',
      }}
    />
  );
};

const LivestreamContainerWrapper: React.FC = () => {
  const { data: memberData } = useAppSelector((state) => state.me);
  if (!memberData) {
    return null;
  }
  return <LivestreamContainer memberData={memberData} />;
};

export default LivestreamContainerWrapper;
