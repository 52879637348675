/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import ballImg from 'assets/images/prizes/ball_light.png';
import clplogo from 'assets/images/prizes/prize-logo.png';
import title1 from 'assets/images/prizes/prize-title-1.png';
import title2 from 'assets/images/prizes/prize-title-2.png';
import title3 from 'assets/images/prizes/prize-title-3.png';
import title4 from 'assets/images/prizes/prize-title-4.png';
import title5 from 'assets/images/prizes/prize-title-5.png';
import title6 from 'assets/images/prizes/prize-title-6.png';
import titleSpecial from 'assets/images/prizes/prize-title-special.png';
import specialBall from 'assets/images/prizes/special-ball.png';
// import backgroundSpecial from 'assets/images/prizes/bg-special-prize.png';
import specialText from 'assets/images/prizes/special-text.png';
import logo from 'assets/images/quiz/quiz-logo.png';
import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import mapModifiers, { numberWithCommas } from 'utils/functions';

export type PrizeNotifyTypes = {
  number: number;
  shopName: string;
  address: string;
  area: string;
}

interface PrizeNotifyProps {
  data?: PrizeNotifyTypes;
  prizeName?: string;
  prizeNumber: number;
  isSpecial?: boolean;
  award?: number;
}

const prizeTitle = (prizeNo: number) => {
  switch (prizeNo) {
    case 1:
      return title1;
    case 2:
      return title2;
    case 3:
      return title3;
    case 4:
      return title4;
    case 5:
      return title5;
    case 6:
      return title6;
    default:
      return '';
  }
};

const PrizeNotify: React.FC<PrizeNotifyProps> = ({
  data, prizeName, isSpecial, prizeNumber, award,
}) => (
  <div className={mapModifiers('t-prizeNotify', isSpecial && 'special')}>
    <div className="t-prizeNotify_header">
      <div className="t-prizeNotify_logo">
        <Image imgSrc={logo} alt="Quiz Logo" ratio="234x23" size="contain" />
      </div>
      <div className="t-prizeNotify_clplogo">
        <Image imgSrc={clplogo} alt="Quiz Logo" ratio="277x100" size="contain" />
      </div>
    </div>
    <div className="t-prizeNotify_content">
      <Container fullScreen>
        <div className="t-prizeNotify_content_wrap">
          <div className={`t-prizeNotify_heading${isSpecial ? ' special' : ''}`}>
            <img src={isSpecial ? titleSpecial : prizeTitle(prizeNumber)} alt="prize" />
          </div>
          {
              isSpecial && (
              <div className="t-prizeNotify_price">
                <Heading type="h3" modifiers={['700', 'prizeSpecial', 'center']}>
                  <span>Trị giá</span>
                  {numberWithCommas(award)}
                  <sup>VNĐ</sup>
                </Heading>
              </div>
              )
            }
          <div className="t-prizeNotify_content_flex">
            {
              data && (
                <>
                  <div className="t-prizeNotify_number">
                    <Image size="cover" imgSrc={isSpecial ? specialBall : ballImg} ratio={isSpecial ? '567x308' : '1x1'} alt="number" />
                    <div className="number">{data.number}</div>
                  </div>
                  <div className="t-prizeNotify_info">
                    <div className="t-prizeNotify_info_name">
                      Cửa Hàng
                      {' '}
                      <span className={mapModifiers('t-prizeNotify_info_shopname', isSpecial && 'special')}>{data.shopName}</span>
                    </div>
                    <div className="t-prizeNotify_info_address">
                      <Text modifiers={isSpecial ? ['700', 'uppercase', 'white', '24x33'] : ['700', 'uppercase', 'white', '24x33', 'center']}>
                        địa chỉ:
                        {' '}
                        {data.address}
                      </Text>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </Container>
    </div>
  </div>
);

PrizeNotify.defaultProps = {
  data: undefined,
  isSpecial: undefined,
  prizeName: undefined,
  award: undefined,
};

export default PrizeNotify;
