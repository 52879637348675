/* eslint-disable no-unused-vars */
import React from 'react';

import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import HeadingSection from 'components/molecules/HeadingSection';
import NumberBannerLayout from 'components/organisms/NumberBannerLayout';

interface WaitingProps {
  account?: string;
  timeStart?: string;
  timeEnd?: string;
  address?: string;
  numberAvailable?: number;
}
interface WaitingInfoProps {
  label?: string;
  value?: string;
}

const WaitingInfo: React.FC<WaitingInfoProps> = ({
  label, value,
}) => (
  <div className="t-waiting_info_item">
    <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22', 'center']}>
      {label}
      {' '}
      <Text type="span" modifiers={['700', 'pigment', 'fontUrmAvo', '16x22']}>
        {value}
      </Text>
    </Text>
  </div>
);

const Waiting: React.FC<WaitingProps> = ({
  account,
  timeStart, timeEnd,
  address, numberAvailable,
}) => (
  <div className="t-waiting">
    <NumberBannerLayout>
      <div className="t-waiting_panel">
        <div className="t-waiting_panel_wrap">
          <div className="t-waiting_heading">
            <HeadingSection modifiers="secondary">
              CHƯƠNG TRÌNH HỘI NGHỊ KHÁCH HÀNG CLP 2023
            </HeadingSection>
          </div>
          <div className="t-waiting_content">
            <Text modifiers={['700', 'pigment', 'center', 'uppercase', 'fontUrmAvo', '19x26']}>
              Thông tin THỜi gian chọn số
            </Text>
            <div className="t-waiting_info">
              {/* Account  */}
              <WaitingInfo
                label="Khu vực:"
                value={address}
              />
              {/* Time  */}
              <WaitingInfo
                label="Thời gian bắt đầu chọn số:"
                value={timeStart}
              />
              {/* Address  */}
              <WaitingInfo
                label="Thời gian kết thúc chọn số: "
                value={timeEnd}
              />
            </div>
            <div className="u-mt-24">
              <Text modifiers={['400', 'jet', 'fontUrmAvo', 'center', '16x22']}>
                Mọi thắc mắc xin vui lòng liên hệ tổng đài:
                {' '}
                <Link href="tel:0918454049" useExternal>
                  <Text type="span" modifiers={['700', 'jet', 'fontUrmAvo', '16x22']}>
                    0918 45 40 49
                  </Text>
                </Link>
              </Text>
            </div>
            {/* <div className="u-mt-md-32 u-mt-18">
              <Text modifiers={['fontUrmAvo', '20x24', 'pigment', 'center', 'uppercase', '700']}>
                chúc quý khách may mắn
              </Text>
            </div> */}
          </div>
        </div>
      </div>
    </NumberBannerLayout>
  </div>
);

Waiting.defaultProps = {
  account: undefined,
  address: undefined,
  numberAvailable: undefined,
  timeEnd: undefined,
  timeStart: undefined,
};
WaitingInfo.defaultProps = {
  label: undefined,
  value: undefined,
};

export default Waiting;
